<template>
  <div>
    <div class="patientRoom mt10">
      <div class="dNOmer mt10 mb-3">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">{{ $t('message.hospital') }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/hospitalPatient' }">{{ $t('message.patient_histories') }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ patientName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div>
          <el-button v-if="lastHistory.is_ended != 0" disabled type="danger" style="margin-right: 20px; height: 36px;" @click="end(getLastHistoryId)">{{ $t('message.complated') }}</el-button>
          <el-button v-else type="danger" style="margin-right: 20px; height: 36px;" @click="end(getLastHistoryId)">{{ $t('message.complated') }}</el-button>
          <hospitalCodes style="margin-top: 1px; min-width: 300px;" :hospital-patient-id="getId" :hospital-code="getLastHistoryId"></hospitalCodes>
        </div>
      </div>

      <div class="patientRoom_tabs" v-loading="loadingData">
        <div class="link_blocks d-flex"> 
          <router-link class="action" :to="{name: 'patientHome'}">{{ $t('message.main') }}</router-link>
          <router-link class="action" :to="{name: 'hospitalization'}">{{ $t('message.hospitalization') }}</router-link>
          <router-link :to="{name: 'patientService'}">{{ $t('message.services') }}</router-link>
          <router-link :to="{name: 'patientConclusion'}">{{ $t('message.conclusions') }}</router-link>
          <router-link :to="{name: 'dailyTreatment'}">{{ $t('message.dailyTreatment') }}</router-link>
          <router-link :to="{name: 'physiotherapy'}">{{ $t('message.physiotherapy') }}</router-link>
          <router-link :to="{name: 'clientBalance'}">{{ $t('message.cabinat_balance') }}</router-link>
          <div v-if="lastHistory.is_ended != 0" class="left_textLastHistory_off ">{{ $t('message.hospitalization_complated') }}! <span><b>{{ $t('message.user') }}:</b> {{lastHistory.endedUser ? lastHistory.endedUser.name : ''}} {{lastHistory.endedUser ? lastHistory.endedUser.surname : ''}} {{lastHistory.endedUser ? lastHistory.endedUser.patronymic : ''}}</span></div>
          <h4></h4>
          <!-- <router-link :to="{name: '/'}">Операция</router-link> -->
        </div>
        <div class="blocks minH">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import hospitalCodes from '@/components/selects/hospitalCode';

    export default {
        mixins:[drawer],
        name: "patientRooms",
        components:{
          hospitalCodes,
        },
        data(){
            return {
              loadingData: false,
              selectedModel: {},
                drawer: {
                    create: {
                        name: "create",
                        status: false,
                        component: 'componentDrawerCreate'
                    }
                }
            }
        },

        computed:{
            ...mapGetters({
                lastHistory: 'dailyTreatments/lastHistory',
                model: 'hospitalPatients/model',
                patientName: 'hospitalPatients/patientName',
                columns: "hospitalPatients/columns",
                pagination: "hospitalPatients/pagination",
                filter: "hospitalPatients/filter",
                sort: "hospitalPatients/sort",
            }),
            actions: function() {
              return ['edit', 'delete'];
            },
            getId(){
              return this.$route.params.id;
            },
            getLastHistoryId () {  
              if (this.lastHistory) {
                  return this.lastHistory.id
              }
              return null;
            }
        },

        beforeRouteLeave (to, from, next){
            this.$store.commit('dailyTreatments/EMPTY_CODE','');
            next();
        },
        beforeDestroy() {
          this.$store.commit('hospitalPatients/SET_CODE','');
        },
        mounted() {
          if (_.isArray(this.lastHistory) && this.lastHistory.length == 0) {
            this.getLastHistory({id: this.$route.params.id}) ;                                   
          }
          if (!this.lastHistory) {
            this.getLastHistory({id: this.$route.params.id}) ;                       
          }
        },
        // beforeRouteLeave(to, from, next) {
        //     this.empty();
        //     next();
        // },
        methods: {
            ...mapActions({
                updateList: 'hospitalPatients/index',
                updateSort: "hospitalPatients/updateSort",
                updateFilter: "hospitalPatients/updateFilter",
                updateColumn: "hospitalPatients/updateColumn",
                updatePagination: "hospitalPatients/updatePagination",
                editModel: 'hospitalPatients/show',
                endHospitalization: 'patientHistories/endHospitalization',
                empty: 'hospitalPatients/empty',
                delete: 'hospitalPatients/destroy',
                refreshData: 'hospitalPatients/refreshData',
                getLastHistory: 'dailyTreatments/getLastNumber',
                selectedNumber: 'dailyTreatments/getSelectedNumber',
            }),
            end(id){
              this.$confirm(
                "Вы действительно хотите Заканчивать",
                "Предупреждение",
                {
                  confirmButtonText: "Да",
                  cancelButtonText: "Отмен",
                  type: "warning"
                }
              ).then(() => {
                  if (!this.loadingData) {
                      this.loadingData = true;
                      const hId = {hospitalization_id: id};
                      this.endHospitalization(hId).then(res => {
                          const payload = {history_id: this.lastHistory.id};
                          this.selectedNumber(payload);                       
                          this.loadingData = false;
                      }).catch(err => {
                          this.loadingData = false
                      });
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "warning",
                    message: "Операция отменена"
                  });
                });
            },
            fetchData() {
                const query = { ...this.filter, ...this.pagination, ...this.sort };
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
            refresh() {
                this.refreshData()
                    .then(res => {
                        this.filterForm = JSON.parse( JSON.stringify( this.filter ));
                    })
                    .catch(err => {

                    })
            },
             async show(id) {
                this.$router.push("/patientRoom/main/" + id);
              },
            edit(model){
                this.selectedModel = model;
                this.drawer.update.status = true;
            },
            destroy(model){
                this.delete(model.id)
                    .then(res => {
                        this.$alert(res);
                        this.fetchData()
                    })
                    .catch(err => {
                       console.log(err)
                    })
            },
            emptyModel(){
                this.empty()
            },
        },
        
};
</script>

<style lang="scss" >
.patientRoom_tabs {
  background-color: #fff;
  border-radius: 5px;
}
.link_blocks {
  border-bottom: 1px solid #c0c4cc;
}
.link_blocks a {
  padding: 10px;
  display: inline-block;
  margin-bottom: -1px;
  color: #000;
}

.link_blocks a:hover {
  text-decoration: none;
}
.link_blocks .router-link-exact-active.router-link-active {
  border-bottom: 1px solid #007bff;
  color: #007bff;
}
.minH {
  min-height: 800px;
}
.dNOmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left_textLastHistory_off{
    padding-top: 7px;
    text-align: right;
    flex: 1;
    padding-right: 10px;
    color: red;
    font-size: 16px;
    span{
      margin-left: 20px;
    color: #717171;
    }
}
</style>